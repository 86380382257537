<template>
  <div class="main-content" :class="{ 'has-item-selected': selectedItem }">
    <div class="centered-loading-wrapper column" v-if="loading">
      <LoadingSpinner />
      <p>Loading Items...</p>
    </div>
    <div v-else>
      <div v-if="viewToShow === 'list'" class="item-list">
        <h3 class="page-heading">Items</h3>
        <button class="text-icon contained primary" @click="createNewItem">
          <Icon :path="mdiPlus" />
          <span class="text">Create</span>
        </button>
        <ag-grid-vue
          class="ag-theme-alpine items"
          @gridReady="onGridReady"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="rowData"
          @cellClicked="onCellClicked"
          rowSelection="single">
        </ag-grid-vue>
      </div>
      <div v-if="viewToShow == 'editor'">
        <ItemEditor
          :selectedItemProp="selectedItem"
          :isNewItemProp="isNewItem"
          @changeView="changeView" />
      </div>
    </div>
    <teleport to="body">
      <transition name="fade">
        <YesNoDialog
          id="unsavedChanges"
          v-if="showModal === 'confirmDelete'"
          :maxWidth="'400'"
          :nameYes="'Confirm'"
          :funcYes="deleteItem"
          :nameNo="'Cancel'"
          :funcNo="closeModal"
          @close="showModal = ''">
          <h4>Delete Item?</h4>
          <p style="text-align: center; padding: 1rem">
            <strong>{{ clickedRow.data.name }}</strong>
          </p>
        </YesNoDialog>
      </transition>
      <transition name="fade">
        <ModalDialog
          :maxWidth="'600'"
          :hideCloseButton="true"
          v-if="showModal === 'showAffectedOrders'">
          <template v-slot:title>Orders Affected</template>

          <ConfirmCancellation
            :affectedOrders="affectedOrders"
            @close="showModal = ''"
            @saveExistingItemConfirmed="deleteItemConfirmed">
            <template v-slot:message
              >This change has been determined as high risk. High risk changes
              include things such as adding allergens or changing dietary
              types.</template
            ></ConfirmCancellation
          >
        </ModalDialog>
      </transition>
    </teleport>
  </div>
</template>

<script>
  import { ApiItem } from "@tucktrucks/platform-base-private";
  import store from "@/store";
  import { mapGetters } from "vuex";
  import LoadingSpinner from "@/components/LoadingSpinner";
  import { AgGridVue } from "ag-grid-vue3";
  import ItemEditor from "@/components/Menus/ItemEditor.vue";
  import { useToast } from "vue-toastification";
  import { cloneButtonText } from "@/helpers/buttonTextDeteminator";
  import ModalDialog from "@/components/Dialogs/ModalDialog.vue";
  import YesNoDialog from "@/components/Dialogs/YesNoDialog.vue";
  import ConfirmCancellation from "@/components/ConfirmCancellation.vue";
  import { mdiPlus } from "@mdi/js";
  import { OPERATORTYPEOUTLET } from "@/constants/operatorTypes.ts";

  export default {
    components: {
      AgGridVue,
      ItemEditor,
      LoadingSpinner,
      ModalDialog,
      YesNoDialog,
      ConfirmCancellation,
    },
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        viewToShow: "list",
        unsavedChanges: false,
        showModal: "",
        columnDefs: [],
        defaultColDef: null,
        rowData: [],
        items: [],
        allergens: [],
        alterations: [],
        gridApi: null,
        columnApi: null,
        selectedRowNode: null,
        selectedItem: null,
        isNewItem: false,
        clickedRow: null,
        affectedOrders: [],
        loading: true,
        mdiPlus,
      };
    },
    computed: {
      ...mapGetters({
        apiPublicAllergensGetAll: "apiPublicAllergens/getAll",
        apiPrivateAllergensGetById: "apiPrivateAllergens/getById",
        apiPrivateItemsGetByOutletId: "apiPrivateItems/getByOutletId",
        apiPrivateItemsDeleteById: "apiPrivateItems/deleteById",
      }),
    },
    watch: {
      operatorId: function () {
        this.refreshGrid(null);
      },
    },
    methods: {
      refreshGrid(item) {
        this.loading = true;
        const outletId = this.operatorId;
        this.apiPrivateItemsGetByOutletId(outletId).then((data) => {
          this.rowData = data;
          this.loading = false;
        });

        this.selectedItem = item;
      },
      onGridReady(params) {
        // get the grid and column api
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.autoSizeAll();
      },
      // auto size AG Grid columns
      autoSizeAll() {
        this.gridApi.sizeColumnsToFit();
      },
      onCellClicked(row) {
        this.clickedRow = row;

        if (row.column.colId === "clone") {
          this.cloneItem(row);
        } else if (row.column.colId === "delete") {
          this.showModal = "confirmDelete";
        } else {
          this.handleNewSelection();
        }
      },
      // A new row has been selected, reset things, etc
      handleNewSelection() {
        const rowData = this.clickedRow.data;

        this.isNewItem = false;
        this.changesMade = false;
        this.selectedRowNode = this.clickedRow.node;
        this.selectedItem = rowData;
        this.selectedRowNode.setData(this.selectedItem);
        this.changeView("editor");
      },

      // Clicked Create button, create a blank object
      createNewItem() {
        this.isNewItem = true;
        this.selectedRowNode = null;
        this.gridApi.deselectAll();
        this.selectedItem = new ApiItem();
        // set default tax rate value to standart rate with id 1
        if (this.selectedItem.taxRateId === 0) this.selectedItem.taxRateId = 1;
        this.changeView("editor");
      },

      // Clicked Clone button, create a cloned object
      cloneItem(row) {
        this.cloning = true;
        let ele = row.event.srcElement;
        let cloneTextElement = cloneButtonText(ele);
        cloneTextElement.innerText = "Cloning...";

        store.state.apiPrivate.client.endpoints.items
          .cloneItem(row.data.id)
          .then((response) => {
            if (response.status == 200) {
              const outletId = this.operatorId;
              this.apiPrivateItemsGetByOutletId(outletId).then((data) => {
                this.rowData = data;
                this.loading = false;
                this.cloning = false;
                cloneTextElement.innerText = "Clone";
              });
              useToast().success("Item cloned.");
            } else {
              useToast().error(
                "Failed to create item. Please contact Support."
              );
            }
          });

        //delete row.data.id;

        // TO DO: Send the cloned object to API and Reload the grid
        // return store.state.apiPrivate.client.endpoints.items
        //   .createVendorItem(row.data, this.operatorId)
        //   .then((response) => {
        //     if (response.status == 201) {
        //       this.selectedItemEdit.id = response.data.data.id;
        //       this.selectedItem = Object.assign({}, this.selectedItemEdit);
        //       this.refreshGrid(this.selectedItemEdit);
        //       useToast().success("Item cloned.");
        //     } else {
        //       useToast().error("Failed to create item. Please contact Support.");
        //     }
        //   });
      },
      deleteItem() {
        this.showModal = "";
        this.apiPrivateItemsDeleteById(this.clickedRow.data.id).then(
          (response) => {
            if (response.status === "Deleted") {
              useToast().success("Item deleted.");

              const selectedRow = this.gridApi.getSelectedRows();

              this.gridApi.applyTransaction({ remove: selectedRow });
            } else if (response.status == 409) {
              this.handleItemDeleteConflict(response.data);
            } else {
              useToast().error(
                "Failed to delete item. Please contact Support."
              );
            }
          }
        );
      },
      handleItemDeleteConflict(payload) {
        useToast().warning("Could not delete item due to existing orders.");

        this.showModal = "showAffectedOrders";
        this.affectedOrders = payload.meta["affected-orders"].$values;
      },
      deleteItemConfirmed() {
        return this.apiPrivateItemsDeleteById(
          this.clickedRow.data.id,
          "affected-orders=cancel"
        ).then((response) => {
          if (response.status == 200) {
            this.unsavedChanges = false;
            this.$emit("changesMade", this.selectedItemEdit);
            store.dispatch("apiPrivateItems/upsert", this.selectedItemEdit);

            useToast().success("Affected Orders cancelled and item deleted.");
          } else {
            useToast().error("Failed to deleted item. Please contact Support.");
          }
        });
      },
      changeView(viewToChange) {
        if (viewToChange === "list") this.refreshGrid();
        this.viewToShow = viewToChange;
      },
      closeModal() {
        this.showModal = "";
      },
      getItemsFromRepo() {
        const outletId = this.operatorId;
        this.apiPrivateItemsGetByOutletId(outletId).then((data) => {
          this.loading = false;
          this.rowData = data;
        });
      },
    },

    beforeMount() {
      if (this.operatorType !== OPERATORTYPEOUTLET) {
        this.$router.push({ name: "/dashboard" });
        return;
      }

      this.apiPublicAllergensGetAll().then((data) => {
        this.allergens = data;
      });

      this.getItemsFromRepo();

      this.defaultColDef = {
        suppressMenu: true,
        suppressMovable: true,
      };

      this.columnDefs = [
        // hidden cols
        { field: "id", hide: true },
        { field: "complexity", hide: true },
        { field: "dietary", hide: true },
        { field: "ingredients", hide: true },
        // visible cols
        {
          field: "name",
          filter: true,
          width: 250,
          suppressSizeToFit: true,
          floatingFilter: true,
          resizable: true,
        },
        {
          field: "version",
          width: 100,
          suppressSizeToFit: true,
          cellRenderer: (params) => {
            return `v${params.value}`;
          },
        },
        {
          field: "price",
          width: 100,
          suppressSizeToFit: true,
          resizable: true,
          cellRenderer: (params) => {
            if (params.value == null) {
              return "-";
            }

            return this.formatMoney(params.value);
          },
        },
        {
          field: "description",
          filter: true,
          floatingFilter: true,
          resizable: true,
          width: 200,
          valueFormatter: (params) => params.value || "-",
        },

        {
          field: "allergens",
          hide: true,
          suppressSizeToFit: true,
          resizable: true,
          cellRenderer: (params) => {
            if (params.value == null) {
              return "0";
            }

            return params.value.length;
          },
        },
        {
          field: "alterations",
          hide: true,
          resizable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => {
            if (params.value == null) {
              return "0";
            }

            return params.value.length;
          },
        },
        {
          field: "notes",
          width: 80,
          resizable: true,
          cellRenderer: (params) => {
            return params.value || "-";
          },
        },
        {
          field: "clone",
          width: 100,
          cellClass: "action",
          headerName: "",
          suppressSizeToFit: true,
          cellRenderer: () => {
            return '<button @click="cloneItem" type="button" class="text-icon small inline"><span class="icon"><span class="mdi-icon" role="img"><svg fill="#333" class="material-design-icon__svg" width="18" height="18"viewBox="0 0 24 24"><path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" /></svg></span></span><span class="text">Clone</span></button>';
          },
        },
        {
          field: "delete",
          width: 100,
          flex: 1,
          cellClass: "action",
          headerName: "",
          suppressSizeToFit: true,
          cellRenderer: () => {
            return '<button @click="deleteItem" type="button" class="text-icon small inline"><span class="icon"><span class="mdi-icon" role="img"><svg fill="#333" class="material-design-icon__svg" width="18" height="18"viewBox="0 0 24 24"><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg></span></span><span class="text">Delete</span></button>';
          },
        },
      ];
    },
  };
</script>

<style lang="scss" scoped>
  .prompt {
    display: flex;
    justify-content: space-around;
    margin-top: 3rem;
  }

  .linked-menus-warning {
    background: #ffdbdb;
    border-radius: $card_radius;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
  }

  .linked-menu {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;

    &:hover {
      color: rgb(76, 76, 76);
    }

    .menu-icon {
      margin-right: 0.5rem;
      position: relative;
      bottom: -2px;
    }
  }

  .ag-theme-alpine {
    margin-top: 1rem;
  }
  .item-list {
    margin: 1rem 0;
  }
</style>
