export const cloneButtonText = (ele) => {
  let eleParentNodeName = ele.parentNode.nodeName;
  let cloneTextEle;

  if (eleParentNodeName === "BUTTON") cloneTextEle = ele;

  if (eleParentNodeName === "DIV") cloneTextEle = ele.querySelector(".text");

  if (eleParentNodeName === "SPAN") {
    if (ele.parentNode.className === "icon") {
      cloneTextEle = ele.parentNode.nextElementSibling;
    } else {
      cloneTextEle = ele.querySelector(".text");
    }
  }
  return cloneTextEle;
};
